import * as React from "react"
import DefaultLayout from "../layouts/default";
import SEO from "../components/seo";

import jakKorzystacGrafika from "../images/jakkorzystac_grafika.svg"
import jakKorzystacTekst from "../images/jakkorzystac_tekst.svg"
import lubTekst from "../images/lub_tekst.svg"


const JakKorzystacPage = () => {
    return (
        <DefaultLayout>
            <SEO title="jak korzystać?" />
            <article className="prose prose-xl">
                <img className="h-96 mx-auto" src={jakKorzystacGrafika} alt="jak korzystać"></img>
                <img className="h-16 mx-auto" src={jakKorzystacTekst} alt="jak korzystać"></img>
                <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/bIXgagsAO6w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <p>
                    <ol>
                        <li>Skorzystaj z wyszukiwarki. Wybierz poziom edukacyjny i przedmioty.</li>
                        <li>Na mapie pokażą się trasy, które będą zawierały wybrane kryteria</li>
                        <li>Najedź na trasę. Pojawi się ikonka z nazwą trasy. Kliknij na “więcej”, a przeniesiesz się na stronę z opisem, mapą trasy i załączonym scenariuszem w formacie pdf. Początek trasy oznaczony jest na mapie czerwonym znacznikiem.</li>
                        <li>Na mapie, na trasie zaznaczone są punkty - miejsca realizacji zadań. Po kliknięciu na nie, pokaże się temat i jednozdaniowy opis.</li>
                        <li>Dokładnie przeczytaj scenariusz i wydrukuj materiały dla uczniów w ilości podanej w komentarzu dla nauczyciela.</li>
                        <li>Ruszaj z klasą w teren!</li>
                    </ol>
                    <img className="h-12 mx-auto" src={lubTekst} alt="lub"></img>
                    <ol>
                        <li>W pasku menu wejdź w zakładkę “Scenariusze”</li>
                        <li>Skorzystaj z wyszukiwarki lub wybierz scenariusz, przeglądając po kolei załączone materiały. Nie znudzisz się ;)</li>
                    </ol>
                </p>
                <p>Nauczycielu! Nie musisz być Alfą i Omegą i znać odpowiedzi na wszystkie pytania ze wszystkich przedmiotów. Scenariusz jest tak skonstruowany, żeby uczniowie sami poradzili sobie z wykonaniem zadań. Bądź ich Opiekunem, Przewodnikiem, Tutorem.</p>
                <p>Pierwsza część scenariusza to “Instrukcja dla Nauczyciela”. Tu znajdziesz listę przedmiotów, których dotyczą zadania, podstawę programową, trasę i niezbędnik.</p>
                <p>Druga część scenariusza to “Materiały dla Uczniów”, czyli zadania, wyzwania i polecenia.</p>
                <p>Po przejściu całej trasy, napisz co sądzisz o scenariuszu i poproś o to samo swoich uczniów. Ankiety ukryte są pod kodami qr odpowiednio w “Instrukcji dla Nauczyciela” lub na końcu “Materiałów dla uczniów”.</p>

               

               </article>
           
        </DefaultLayout>
    )
}

export default JakKorzystacPage